import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				FAQ - FAQ chez Vitaclub Gym
			</title>
			<meta name={"description"} content={"Vous avez des questions ? Nous avons les réponses ! Plongez dans notre foire aux questions pour en savoir plus sur ce que Vitaclub Gym propose et comment nous pouvons vous aider dans votre parcours de remise en forme."} />
			<meta property={"og:title"} content={"FAQ - FAQ chez Vitaclub Gym"} />
			<meta property={"og:description"} content={"Vous avez des questions ? Nous avons les réponses ! Plongez dans notre foire aux questions pour en savoir plus sur ce que Vitaclub Gym propose et comment nous pouvons vous aider dans votre parcours de remise en forme."} />
			<meta property={"og:image"} content={"https://provencelavend.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://provencelavend.com/img/6158221.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://provencelavend.com/img/6158221.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://provencelavend.com/img/6158221.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://provencelavend.com/img/6158221.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://provencelavend.com/img/6158221.png"} />
			<meta name={"msapplication-TileImage"} content={"https://provencelavend.com/img/6158221.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="60px 0" sm-padding="40px 0" color="--dark">
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="33.333%" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text as="h2" font="--headline1" margin="0 0 8px 0">
							FAQ
						</Text>
						<Text as="p" font="--lead" margin="0" color="--greyD2">
							Vous avez des questions ? Nous avons les réponses ! Plongez dans notre foire aux questions pour en savoir plus sur ce que Vitaclub Gym propose et comment nous pouvons vous aider dans votre parcours de remise en forme.
						</Text>
					</Box>
				</Box>
				<Box padding="16px 16px 16px 16px" width="66.66%" md-width="100%">
					<Box display="flex" flex-direction="row" flex-wrap="wrap">
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Ai-je besoin d'une expérience préalable en salle de sport pour m'inscrire ?
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
									Absolument pas ! Vitaclub accueille des membres de tous niveaux, des débutants aux athlètes chevronnés.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Quelles sont les heures d'ouverture de la salle de sport ?
								</Text>
							</Box>
							<Text as="p" font="--base" margin="12px 0" color="--greyD2">
								Nous sommes ouverts de 6h à 22h en semaine et de 8h à 20h le week-end, ce qui vous permet d'intégrer une séance d'entraînement dans votre emploi du temps chargé.
							</Text>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Puis-je essayer un cours avant de m'engager ?
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
									Oui, nous offrons un cours d'essai gratuit aux nouveaux membres. C'est un excellent moyen de se faire une idée de notre salle de sport et de notre communauté.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Proposez-vous un entraînement personnel ?
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
									Oui, nous avons des entraîneurs personnels certifiés qui peuvent travailler avec vous pour créer un plan d'entraînement personnalisé basé sur vos objectifs.
								</Text>
							</Box>
						</Box>
					</Box>
				</Box>
				<Box display="flex" flex-direction="row" flex-wrap="wrap">
					<Box
						display="flex"
						flex-direction="column"
						width="33%"
						padding="16px 16px 16px 16px"
						sm-width="100%"
					>
						<Box display="flex" flex-direction="column">
							<Text as="h3" font="--headline3" margin="12px 0">
								Y a-t-il des restrictions d'âge pour s'inscrire ?
							</Text>
							<Text as="p" font="--base" margin="12px 0" color="--greyD2">
								Les membres doivent être âgés d'au moins 16 ans. Cependant, nous proposons également des programmes de fitness spécialisés pour les jeunes.
							</Text>
						</Box>
					</Box>
					<Box
						display="flex"
						flex-direction="column"
						width="33%"
						padding="16px 16px 16px 16px"
						sm-width="100%"
					>
						<Box display="flex" flex-direction="column">
							<Text as="h3" font="--headline3" margin="12px 0">
								Que dois-je apporter lors de ma première visite ?
							</Text>
						</Box>
						<Text as="p" font="--base" margin="12px 0" color="--greyD2">
							Nous vous recommandons d'apporter des vêtements d'entraînement, des chaussures de sport, une bouteille d'eau et une serviette. Des vestiaires et des douches sont à votre disposition.
						</Text>
					</Box>
					<Box
						display="flex"
						flex-direction="column"
						width="33%"
						padding="16px 16px 16px 16px"
						sm-width="100%"
					>
						<Box display="flex" flex-direction="column">
							<Text as="h3" font="--headline3" margin="12px 0">
								Fournissez-vous des conseils en matière de nutrition ?
							</Text>
							<Text as="p" font="--base" margin="12px 0" color="--greyD2">
								Oui, nos nutritionnistes sur place sont disponibles pour vous aider à élaborer un plan d'alimentation qui complète vos objectifs de remise en forme.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				justify-content="center"
				lg-width="100%"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Box
					width="100%"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					padding="0px 0px 100% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						src="https://provencelavend.com/img/8.jpg"
						position="absolute"
						display="block"
						width="100%"
						left={0}
						min-height="100%"
						object-fit="cover"
						top={0}
						right={0}
						bottom={0}
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				flex-direction="column"
				lg-width="100%"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
				>
					<Image
						bottom={0}
						src="https://provencelavend.com/img/9.jpg"
						top={0}
						display="block"
						width="100%"
						left={0}
						right={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Si votre question n'a pas été traitée ici, n'hésitez pas à contacter notre personnel amical. Nous sommes toujours là pour vous aider et vous guider sur le chemin de la remise en forme.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});